import React from 'react';
import headshot1 from '../../assets/images/yamille.jpg';
import rdiazimage from '../../assets/images/rdiaz.jfif';
import stephan from '../../assets/images/stephan.jfif';
import rosimar from '../../assets/images/rosimar.jfif';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const people = [
  {
    name: 'Yamille Morales',
    role: 'Founder and CEO',
    imageUrl: headshot1,
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/yamillemorales/',
  },
  {
    name: 'Stephan Elias',
    role: 'Developer Lead',
    imageUrl: stephan,
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/stephanelias/',
  },
  {
    name: 'Rosimar Cruz',
    role: 'Customer Success',
    imageUrl: rosimar,
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/rosimar-cruz-v%C3%ADas-a30a27172/',
  },
  {
    name: 'Rodrigo Diaz Concha',
    role: 'Software Architect',
    imageUrl: rdiazimage,
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/rdiazconcha/',
  },
]
function Team() {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24"
        positionTransition
        initial={{ opacity: 0, y: 1000, }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { type: 'spring', duration: 0.75 },
        }}
      >
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{t('our_team')}</h2>
            <p className="text-xl text-gray-500">
              {t('our_team_desc')} 
            </p>
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-6">
                    <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src={person.imageUrl} alt={`Image of ${person.name}`} />
                    <div className="space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-gopanzaBlue">{person.role}</p>
                      </div>
                      <ul className="flex justify-center space-x-5">                     
                        <li>
                          <a href={person.linkedinUrl} target="_blank" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Team;
