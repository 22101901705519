import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import CustomerReferences from './shared-components/CustomerReferences';
import Team from '../../shared-components/Team';
import HowItWorks from './shared-components/HowItWorks';
import CTA from './shared-components/CTA';
import WhyUs from './shared-components/WhyUs';
// import homeImage from '../../../assets/images/market.jfif';
// import homeImage from '../../../assets/images/header2.jpg';
// import homeImage from '../../../assets/images/ecommerce-2 flipped.png';
import homeImage from '../../../assets/images/gopanza_ball.svg';
import envConfig from '../../../envConfig';

export default function App() {
  const { t } = useTranslation();
  return (
    <AnimatePresence>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8" />
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block lg:inline">{t('eccomerce_for_franchise_made')}</span>{' '}
                  <span className="block text-gopanzaLightBlue xl:inline">{t('simple')}</span>{' '}
                  {/* <span className="block xl:inline">A comprehensive platform to sell</span>{' '}
                  <span className="block text-lime-500 xl:inline">food</span>{' '}
                  <span className="block  xl:inline">online</span> */}
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  {t('homepage_description')}
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    {/* <a
                      href="https://google.com"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </a> */}
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3 hidden">
                    <a
                      href={envConfig.LiveDemoSite}
                      target="_blank"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                    >
                      {t('live_demo')}
                    </a>
                  </div>
                </div>
            </div>
          </main>
          </div>
        </div>
        <motion.div
          className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2"
          positionTransition
          initial={{ opacity: 0, x: -1000, }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { type: 'tween', duration: 0.5 },
          }}
        >
          {/* <img
            className="h-48 w-full object-cover sm:h-72 md:h-96 lg:64 lg:64"
            src={homeImage}
            alt="eCommerce Site"
          /> */}
          <img
            className="h-0 w-full object-cover py-24 lg:h-full"
            src={homeImage}
            alt="eCommerce Site"
          />
        </motion.div>
      </div>
      <motion.div
        className="bg-white"
        positionTransition
        initial={{ opacity: 0, y: 1000, }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { type: 'spring', duration: 0.5 },
        }}
      >
        <HowItWorks />
        <WhyUs />
        <CTA />
        <CustomerReferences />
        <Team />
      </motion.div>
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">{t('homepage_slogan')}</span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="/contact"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
            >
              {t('schedule_demo')}
            </a>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}
